<script setup>
import { ref, provide } from "vue";
import { Head } from '@inertiajs/vue3';
import Navbar from '@/Layouts/Navbar.vue';
import Sidebar from '@/Layouts/Sidebar.vue';
import CheckIfAuth from "@/Pages/Auth/CheckIfAuth.vue";
import CheckIfMaintenanceAdmin from "@/Pages/Admin/Database/CheckMaintenance.vue";

defineProps({
    title: String,
    headerStyle: {
        type: String,
        default: 'bg-white shadow',
    },
});

let sideBarOpen = ref(false);
provide("sideBarOpen", sideBarOpen);

let updatedNotif = ref("");
provide("updatedNotif", updatedNotif);
</script>

<template>
    <div class="leading-normal tracking-normal" id="main-body">
        <div class="flex flex-wrap">

            <Head :title="__(title)" />

            <Sidebar v-if="$page.props.auth.user && $page.props.auth.user.active" />

            <div class="w-full bg-gray-100 pl-0 min-h-screen" :class="$page.props.auth.user && $page.props.auth.user.active ? 'lg:pl-64' : ''" id="main-content">

                <Navbar />

                <!-- Page Heading -->
                <header v-if="$slots.header" :class="headerStyle">
                    <div class="mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header" />
                    </div>
                </header>

                <!-- Page Content -->
                <main>
                    <CheckIfMaintenanceAdmin />
                    <CheckIfAuth v-if="$page.props.auth.user" />
                    <slot />
                </main>
            </div>

        </div>
    </div>
</template>
