<template>
    <div class="sticky top-0 z-40">
        <div class="w-full h-20 px-6 bg-gray-100 border-b flex items-center justify-between">
            <!-- Left Navbar -->
            <div class="flex">
                <!-- Mobile Hamburger -->
                <div v-if="$page.props.auth.user && $page.props.auth.user.active" class="lg:hidden flex items-center mr-4">
                    <button class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger" @click="toggleSidebar()">
                        <div :title="__('Menu')">
                            <Bars3Icon class="h-7 w-7 stroke-black" />
                        </div>
                    </button>
                </div>

                <!-- Search Bar -->
                <div v-if="$page.props.auth.user && $page.props.auth.user.active" class="relative text-gray-600 hidden md:flex">
                    <InputSearch :for-navbar="true" />
                </div>
            </div>

            <!-- Right Navbar -->
            <div class="flex items-center relative">

                <!-- If User not Logged In -->
                <div class="flex" v-if="!$page.props.auth.user">
                    <div class="flex space-x-8 sm:-my-px sm:ml-10">
                        <NavLink :href="route('login')" v-if="$page.props.canLogin">
                            {{ __('Log in') }}
                        </NavLink>

                        <NavLink :href="route('register')" v-if="$page.props.canRegister">
                            {{ __('Register') }}
                        </NavLink>
                    </div>
                </div>

                <div v-else class="flex">
                    <!-- Notifications -->
                    <div v-if="$page.props.auth.user && $page.props.auth.user.active">
                        <div class="inline-flex items-center mr-3 relative cursor-pointer" @click="showNotifications = true">
                            <BellIcon class="h-6 w-6 text-gray-500 hover:text-gray-700 stroke-2" />
                            <Badge color="theme" :dark="true" size="xs" class="absolute top-0 right-0 inline-flex transform translate-x-1/2 -translate-y-1">{{ countNotif }}</Badge>
                        </div>
                        <Notifications :show="showNotifications" @updateCountNotif="updateCountNotif" @closeNotif="closeNotif" />
                    </div>

                    <!-- Teams Dropdown -->
                    <div class="ml-3 relative" v-if="$page.props.auth.user && $page.props.auth.user.active">
                        <Dropdown v-if="$page.props.jetstream.hasTeamFeatures" align="right" width="60">
                            <template #trigger>
                                <span class="inline-flex rounded-md">
                                    <button type="button"
                                        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition">
                                        {{ __('Teams') }}
                                        <ChevronDownIcon class="h-3.5 w-3.5 mt-0.5 ml-2 -mr-0.5 stroke-2" />
                                    </button>
                                </span>
                            </template>

                            <template #content>
                                <div class="w-60">
                                    <!-- Teams Management -->
                                    <template v-if="$page.props.jetstream.hasTeamFeatures">
                                        <div class="block px-4 py-2 text-xs text-gray-400">{{ __('Manage Teams') }}</div>

                                        <!-- Teams Settings -->
                                        <DropdownLink v-if="$page.props.auth.user.all_teams.length > 0" :href="route('teams')">
                                            {{ __('My Teams') }}
                                        </DropdownLink>

                                        <DropdownLink v-if="$page.props.jetstream.canCreateTeams" :href="route('teams.create')">
                                            {{ __('New Team') }}
                                        </DropdownLink>
                                    </template>
                                </div>
                            </template>
                        </Dropdown>
                    </div>

                    <!-- Settings Dropdown -->
                    <div class="ml-3 relative">
                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <span class="inline-flex rounded-md">
                                    <button type="button"
                                        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition">
                                        {{ $page.props.auth.user.firstname }}
                                        {{ $page.props.auth.user.name }}
                                        <ChevronDownIcon class="h-3.5 w-3.5 mt-0.5 ml-2 -mr-0.5 stroke-2" />
                                    </button>
                                </span>
                            </template>

                            <template #content>
                                <!-- Account Management -->
                                <div class="block px-4 py-2 text-xs text-gray-400">{{ __('Manage Account') }}</div>

                                <DropdownLink :href="route('profile.show')">{{ __('Profile') }}</DropdownLink>

                                <DropdownLink v-if="$page.props.jetstream.hasApiFeatures && $page.props.auth.user.active" :href="route('user.parameters')">
                                    {{ __('Settings') }}
                                </DropdownLink>

                                <DropdownLink v-if="$page.props.jetstream.hasApiFeatures && $page.props.auth.user.active" :href="route('api-tokens.index')">
                                    {{ __('API Tokens') }}
                                </DropdownLink>

                                <div class="border-t border-gray-100" />

                                <!-- Admin Module -->
                                <div v-if="$page.props.role == 'admin'" class="block px-4 py-2 text-xs text-gray-400">{{ __('Administrator Module') }}</div>

                                <DropdownLink v-if="$page.props.role == 'admin'" :href="route('manage.user.index')">
                                    {{ __('User Management') }}
                                </DropdownLink>

                                <DropdownLink v-if="$page.props.role == 'admin'" :href="route('manage.database.index')">
                                    {{ __('Database Management') }}
                                </DropdownLink>

                                <DropdownLink v-if="$page.props.role == 'admin'" :href="route('manage.application.index')">
                                    {{ __('Application Management') }}
                                </DropdownLink>


                                <div v-if="$page.props.role == 'admin'" class="border-t border-gray-100" />

                                <!-- Authentication -->
                                <form @submit.prevent="logout">
                                    <DropdownLink type="button">{{ __('Log out') }}</DropdownLink>
                                </form>
                            </template>
                        </Dropdown>
                    </div>
                </div>

                <!-- Lang Dropdown -->
                <div class="ml-2 relative">
                    <Dropdown align="right" width="16">
                        <template #trigger>
                            <span class="inline-flex rounded-md">
                                <button type="button"
                                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition">
                                    {{ $page.props.locales.current.toUpperCase() }}
                                    <ChevronDownIcon class="h-3.5 w-3.5 mt-0.5 ml-2 -mr-0.5 stroke-2" />
                                </button>
                            </span>
                        </template>

                        <template #content>
                            <span v-for="locale in $page.props.locales.all">
                                <DropdownLink v-if="locale != $page.props.locales.current" :href="route('language', locale)">
                                    {{ locale.toUpperCase() }}
                                </DropdownLink>
                            </span>
                        </template>
                    </Dropdown>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { router } from "@inertiajs/vue3";
import Dropdown from "@/Components/Dropdown.vue";
import DropdownLink from "@/Components/DropdownLink.vue";
import NavLink from "@/Components/NavLink.vue";
import InputSearch from "@/Pages/Search/InputSearch.vue";
import Notifications from "@/Layouts/Notifications.vue";
import Badge from "@/Components/Badge.vue";
import { Bars3Icon, ChevronDownIcon, BellIcon } from '@heroicons/vue/24/outline';

const sideBarOpen = inject("sideBarOpen");

let showNotifications = ref(false);
let countNotif = ref(0);

const logout = () => {
    router.post(route('logout'));
};

const toggleSidebar = () => {
    sideBarOpen.value = !sideBarOpen.value;
};

const updateCountNotif = (newCount) => {
    countNotif.value = newCount;
};

const closeNotif = () => {
    showNotifications.value = false;
};
</script>
